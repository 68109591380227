import React, { useEffect, useContext } from "react"
import { graphql } from "gatsby"

import HeaderContext from "../context/header-context"
import MotionWrapper from "../components/motion-wrapper"
import Container from "../components/container"
import Slab from "../components/slab"
import ContentBuilder from "../components/content-builder"
import Copy from "../components/copy"
import SEO from "../components/seo"

const ServePeopleWellPage = ({ data }) => {
  const {
    entry: [entry],
  } = data.craft

  const { updateHeaderState } = useContext(HeaderContext)

  useEffect(() => {
    updateHeaderState({
      inverseHeaderColor: true,
      section: "Serve people well",
    })
  }, [updateHeaderState])

  const footerLinks = [
    {
      title: "View our work",
      slug: "/made",
    },
    {
      title: "Contact us",
      slug: "/contact",
    },
    {
      title: "Go back home",
      slug: "/",
    },
  ]

  return (
    <>
      <SEO
        description={entry.metaDescription}
        title={entry.metaTitle ? entry.metaTitle : entry.title}
        image={entry.metaImage[0].url}
      />
      <MotionWrapper footerLinks={footerLinks}>
        <Slab first>
          <Container>
            <Copy markup={entry.introText} />
          </Container>
        </Slab>
        <ContentBuilder blocks={entry.contentBuilder} />
      </MotionWrapper>
    </>
  )
}

export const ServePeopleWellPageQuery = graphql`
  {
    craft {
      entry: entries(section: "servePeopleWell", limit: 1) {
        title
        ... on craft_servePeopleWell_servePeopleWell_Entry {
          introText
          contentBuilder {
            ...ContentBuilder
          }
          metaTitle
          metaDescription
          metaImage {
            url
          }
        }
      }
    }
  }
`

export default ServePeopleWellPage
